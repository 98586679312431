import { Box, Heading, Text } from "grommet";
import React from "react";
import TimeLine from '../components/TimeLine';
const SmallResume = () => {
    return(
        <Box align="center" pad="small">
            
             <Box animation="fadeIn" justify="center" background="accent-3">
                <Heading alignSelf="center" level={2}>Profile</Heading>
            </Box>
            <Box animation="fadeIn" justify="center" background="dark-1">
            <Box pad="small">
                
                <Text alignSelf="stretch">As an alumnus from the University of Saskatchewan with a Bachelors in Sceince, I am able to work in a professional environment as part of a team or individually.
                    I am able to continually learn and develop my skills in new programming languages and paradigms. To see more of my skills, please visit me on a desktop!
                </Text>
                </Box>
            </Box>
            
            
{/*
            <Box animation="fadeIn" justify="center" background="accent-3">
            <Heading textAlign="center" level={2}>Education</Heading>
            </Box>
            <Box justify="center" background="light-4">
            
                <Box pad="small">
                <Text alignSelf="stretch">
                    Bachelor's Degree in Computer Science - University of Saskatchewan 2012-2017
                </Text>
                </Box>
            </Box>

            <Box animation="fadeIn" justify="center" background="accent-3">
            <Heading textAlign="center" level={3}>Programming Skills</Heading>
            </Box>
            <Box justify="center" background="dark-1">
            <Fade left cascade big fraction={0.3}>
                <SkillBars skills={config.skills} hue="192" saturation="100" />
            </Fade>
            </Box>

            <Box animation="fadeIn" justify="center" background="accent-3">
            <Heading textAlign="center" level={3}>Tech Experience</Heading>
            </Box>
            <Box justify="center" background="light-4">
            <Fade left cascade big fraction={0.3}>
                <SkillBars skills={config.techSkills} hue="192" saturation="100" />
            </Fade>
            </Box>

            <Box animation="fadeIn" justify="center" background="accent-3">
            <Heading textAlign="center" level={4}>Communication</Heading>
            </Box>
            <Box justify="center" background="dark-1">
            <Fade left cascade big fraction={0.3}>
                <Box pad="small">
                <Text alignSelf="stretch">
                    Excellent communication skills, demonstrated through managing group projects in university, and in the workforce.
                </Text>
                <Text alignSelf="stretch">
                    Able to work in team-oriented environment to tackle complicated technical problems quickly and efficiently.
                </Text>
                </Box>
            </Fade>
            </Box>

            <Box animation="fadeIn" justify="center"background="accent-3">
            <Heading textAlign="center" level={4}>Organizational</Heading>
            </Box>
            <Box justify="center" background="light-4">
            <Fade left cascade big fraction={0.3}>
                <Box pad="small">
                <Text alignSelf="stretch">
                    Detailed and task oriented.
                </Text>
                <Text alignSelf="stretch">
                    Demonstrated leadership skills through supervision at Saskatoon Co-Op and leading several group projects through university.
                </Text>
                <Text alignSelf="stretch">
                    Capable of taking a leadership role in group situations demonstrated with various group projects, as presented on my website.
                </Text>
                </Box>
            </Fade> 
            </Box>*/}
                <TimeLine />
              </Box>
    )
}
export default SmallResume;