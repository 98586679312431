import React, {useEffect, useState} from "react";
const SkillBars = props => {

  const [collapsed, setCollapsed] = useState(true);

  
  useEffect(() => {
    const collapsing = setTimeout(() => {
      setCollapsed(false);
    }, 1000);
    return () => clearTimeout(collapsing);
  }, []);

  return (
      <div className={`skillcontainer ${collapsed ? 'collapsed' : ''}`}>
        <ul className="skills">
          {props.skills.map((skill, index) => 
            <li
              key={skill.type}
              style={{ width: `${skill.level}%`, backgroundColor: `hsl(${props.hue}, ${props.saturation}%, ${100 / (index + 3.5) }%)` }}
            >
              {!collapsed && <p>{skill.type}<span>{skill.level}</span></p>}
            </li>
          )}
        </ul>
        
      </div>
  );
}
export default SkillBars;