import BigResume from '../components/BigResume';
import IndButton from "../components/IndButton";
import config from "../config/config.json";
import { Box, Grommet, Heading, Image, ResponsiveContext } from "grommet";
import React from "react";
import MainBody from "../components/MainBody";
import Profile from "../img/profile.jpg";
import SmallResume from '../components/SmallResume';

const Resume = () => {
  return (
    <Grommet theme={config.theme} full>
      <ResponsiveContext.Consumer>
        {size => (
          <MainBody heading="Resume">
            <Box
              fill
              flex
              align="center"
              pad="small"
              overflow={{ horizontal: "hidden" }}
              className="hiddenScroll"
            >
              <Image className="profile" src={Profile} />
              <Heading
                level="2"
                pad="small"
                margin="none"
                a11yTitle="Evan Closson"
              >
                Evan Closson
                </Heading>
              <Heading
                level="4"
                margin="none"
                justify="center"
                a11yTitle="Software Developer, Raspberry Pi Fanatic, Bachelor of Science"
              >
                Software Developer | B.Sc | Serial Hobbyist
                </Heading>
              {size !== "small" && (
                <Box direction="row" justify="center">
                  {config.pages.map((pageInfo, i) => {
                    return pageInfo[1] === "/resume" ? (
                      ""
                    ) : (
                        <IndButton
                          key={i}
                          icon={pageInfo[0]}
                          link={pageInfo[1]}
                          title={pageInfo[2]}
                        />
                      );
                  })}
                </Box>
              )}
              <hr />
              <Box flex fill align="center" pad="small">
                {size !== "small" ? (
                  <BigResume />
                ) : (
                    <SmallResume />
                )}
              </Box>
            </Box>

          </MainBody>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
};
export default Resume;
