import BannerOne from "../img/project-banner-1.jpeg";
import BannerTwo from "../img/project-banner-2.png";
import BannerThree from "../img/project-banner-3.jpg";
import config from "../config/config.json";
import {
  Anchor,
  Box,
  Grid,
  Grommet,
  Heading,
  Paragraph,
  ResponsiveContext,
  Text
} from "grommet";
import IndButton from "../components/IndButton";
import MainBody from "../components/MainBody";
import Modal from "../components/Modal";
import React from "react";


const Projects = () => {
  return (
    <Grommet theme={config.theme} full>
      <ResponsiveContext.Consumer>
        {size => (
          <MainBody heading="Projects">
            <Box align="center" pad="small">
              <Box direction="row" justify="center">
                <Heading level="2" margin="none" a11yTitle="Projects">
                  Projects
                </Heading>
              </Box>
              {/* Individual buttons based on size. Rendered from the config that will allow multiple pages without having to redefine and add to ButtonLinks. */}
              {size !== "small" && (
                <Box direction="row" justify="center">
                  {config.pages.map((pageInfo, i) => {
                    return pageInfo[1] === "/projects" ? (
                      ""
                    ) : (
                      <IndButton
                        key={i}
                        icon={pageInfo[0]}
                        link={pageInfo[1]}
                        title={pageInfo[2]}
                      />
                    );
                  })}
                </Box>
              )}
              <hr />
              <Box animation="fadeIn" direction="row" margin="small">
                <Text
                  a11yTitle="What I do in my spare time."
                  textAlign="center"
                >
                  Here's some examples of what I do in my spare time.
                </Text>
              </Box>
              <Box flex>
                <Grid
                  columns={{
                    count: size !== "small" ? 3 : 1,
                    size: "auto"
                  }}
                  gap="small"
                >
                  {/* <Modal
                    curSize={size}
                    imageURL={BannerOne}
                    imageDefinition="Counter Strike: Global Offensive Get5 Plugin"
                    shortText="Get5 Web Panel"
                    modalHeading="Get5 Web Panel"
                    modalText={
                      <Paragraph>
                        A webpanel made for controlling Counter-Strike: Global
                        Offensive matches. This project has started me down a
                        road of web development, and loads of python. This
                        project was forked from{" "}
                        <Anchor
                          color="accent-3"
                          href="https://github.com/splewis/get5-web"
                        >
                          Sean Lewis
                        </Anchor>
                        , who is the creator of the plugin{" "}
                        <Anchor
                          color="accent-3"
                          href="https://github.com/splewis/get5"
                        >
                          Get5
                        </Anchor>
                        , which this webpanel integrates with. It 
                        has been used locally by{" "}
                        <Anchor color="accent-3" href="https://sklesports.ca">
                          SKL Esports
                        </Anchor>{" "}
                        to run their CS:GO leagues. It has since been
                        archived for a better, more recent tool, {" "}
                        <Anchor color="accent-3" href="https://github.com/phlexplexico/G5API">
                          G5API
                        </Anchor>{" "}.
                      </Paragraph>
                    }
                    projectURL="https://github.com/phlexplexico/get5-web"
                  /> */}
                  <Modal
                    curSize={size}
                    imageURL={BannerTwo}
                    imageDefinition="3DS"
                    shortText="3Input"
                    modalHeading="3Input/3SharpView"
                    modalText={
                      <Paragraph>
                        A custom 3DS system module using{" "}
                        <Anchor
                          color="accent-3"
                          href="https://github.com/devkitPro/libctru"
                        >
                          libctru
                        </Anchor>{" "}
                        which allows a homebrew 3DS to run custom applications. 
                        My project allows the 3DS to spin up a server in the 
                        background and read inputs from the 3DS and sends it 
                        over the network to a client such as{" "}
                        <Anchor
                          color="accent-3"
                          href="https://github.com/PhlexPlexico/3SharpView"
                        >
                          3SharpView
                        </Anchor>{" "} to view inputs on your PC.
                      </Paragraph>
                    }
                    projectURL="https://github.com/PhlexPlexico/3Input"
                  />
                  <Modal
                    curSize={size}
                    imageURL={BannerThree}
                    imageDefinition="The Legend of Zelda: Majora's Mask 3D Banner"
                    shortText="MM3D Randomizer"
                    modalHeading="The Legend of Zelda: Majora's Mask 3D Randomizer"
                    modalText={
                      <Paragraph>
                        A passion project to randomize items in Majora's Mask 3D.
                        With the help of {" "}<Anchor
                          color="accent-3"
                          href="https://github.com/gamestabled"
                        >
                          gamestabled
                        </Anchor>{" "} and other developers, I've created
                        a base patch for the game that will allow future
                        applications to generate playthroughs with randomized
                        items. This was a lot of work in C++ and decompiling 
                        the base game to figure out where to hook into functions,
                        but the basics are there and now is awaiting an app for
                        full-fledged use.
                      </Paragraph>
                    }
                    projectURL="https://github.com/phlexplexico/mm3dr"
                  />
                  <Modal
                    curSize={size}
                    imageURL={BannerOne}
                    imageDefinition="Counter Strike: Global Offensive Get5 API"
                    shortText="G5API"
                    modalHeading="Get5 API (G5API)"
                    modalText={
                      <Paragraph>
                        A custom-made API that integrates with the Get5 plugin. 
                        This API is intended for other developers to create their
                        own front-ends without the hassle of setting up any back-ends. 
                        Everything is relatively simple to setup on a brand new server
                        and requires very little configuration. Using Express, NodeJS, 
                        Jest and Supertest, this API has given me a bunch of new skillsets
                        that I could never have thought to learn. It is still under active
                        development and can be viewed at the URL given below!
                      </Paragraph>
                    }
                    projectURL="https://github.com/phlexplexico/G5API"
                  />
                  <Modal
                    curSize={size}
                    imageURL={BannerOne}
                    imageDefinition="Counter Strike: Global Offensive Get5 Front-End"
                    shortText="G5V"
                    modalHeading="Get5 Vue (G5V)"
                    modalText={
                      <Paragraph>
                        A custom-made front-end made in Vue.js that integrates with G5API.
                        This front-end started off as a basic use-case, but has slowly
                        evolved since then, to the point where other {" "}<Anchor
                          color="accent-3"
                          href="https://github.com/PhlexPlexico/G5V#used-by"
                        >
                          esport organizations
                        </Anchor>{" "} have adopted it as a replacement for {" "}
                        <Anchor
                          color="accent-3"
                          href="https://github.com/deStrO/eBot-CSGO"
                        >
                          eBot
                        </Anchor>{" "}.

                      </Paragraph>
                    }
                    projectURL="https://github.com/phlexplexico/G5API"
                  />
                </Grid>
              </Box>
            </Box>
          </MainBody>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
};
export default Projects;
