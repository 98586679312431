import config from "../config/config.json";
import { Fade } from "react-reveal";
import { Box, Grid, Heading, Text } from "grommet";
import React from "react";
import SkillBars from '../components/SkillBars';
import TimeLine from '../components/TimeLine';
const BigResume = () => {
    return(
        <Box flex align="center" pad="small">
                <Grid
                  rows={['xxsmall', 'small', 'xxsmall', 
                          'medium', 'small', 'xxsmall', 
                          'small', 'samll']}
                  columns={['small', 'large']}
                  gap="medium"

                  areas={[
                    { name: 'profileHeading', start: [0, 1], end: [0, 1] },
                    { name: 'eduHeading', start: [0, 2], end: [0, 2] },
                    { name: 'skillHeading', start: [0, 3], end: [0, 3] },
                    { name: 'techHeading', start: [0, 4], end: [0, 5] },
                    { name: 'commHeading', start: [0, 6], end: [0, 6] },
                    { name: 'orgnHeading', start: [0, 7], end: [0, 7] },
                    { name: 'profileInfo', start: [1, 1], end: [1, 1] },
                    { name: 'eduInfo', start: [1, 2], end: [1, 2] },
                    { name: 'skillInfo', start: [1, 3], end: [1, 3] },
                    { name: 'techInfo', start: [1, 4], end: [1, 5] },
                    { name: 'commInfo', start: [1, 6], end: [1, 6] },
                    { name: 'orgnInfo', start: [1, 7], end: [1, 7] },
                  ]}
                
                  justifyContent="center"
                >
                  <Box animation="fadeIn" justify="center" gridArea="profileHeading" background="accent-3">
                      <Heading alignSelf="center" level={2}>Profile</Heading>
                  </Box>
                  <Box justify="center" gridArea="profileInfo" background="dark-1">
                    <Fade left cascade big fraction={0.2}>
                    <Box pad="small">
                        <Text alignSelf="stretch">As an alumnus from the University of Saskatchewan with a Bachelors in Sceince, I am able to work in a professional environment as part of a team or individually.
                            I am able to continually learn and develop my skills in new programming languages and paradigms.
                        </Text>
                      </Box>
                    </Fade>
                  </Box>

                  <Box animation="fadeIn" justify="center" gridArea="eduHeading" background="accent-3">
                    <Heading textAlign="center" level={2}>Education</Heading>
                  </Box>
                  <Box justify="center" gridArea="eduInfo" background="light-4">
                    
                    <Fade left cascade big fraction={0.3}>
                      <Box pad="small">
                        <Text alignSelf="stretch">
                          Bachelor's Degree in Computer Science - University of Saskatchewan 2012-2017
                        </Text>
                      </Box>
                    </Fade>
                  </Box>

                  <Box animation="fadeIn" justify="center" gridArea="skillHeading" background="accent-3">
                    <Heading textAlign="center" level={3}>Programming Skills</Heading>
                  </Box>
                  <Box justify="center" gridArea="skillInfo" background="dark-1">
                    <Fade left cascade big fraction={0.3}>
                      <SkillBars skills={config.skills} hue="192" saturation="100" />
                    </Fade>
                  </Box>

                  <Box animation="fadeIn" justify="center" gridArea="techHeading" background="accent-3">
                    <Heading textAlign="center" level={3}>Tech Experience</Heading>
                  </Box>
                  <Box justify="center" gridArea="techInfo" background="light-4">
                    <Fade left cascade big fraction={0.3}>
                      <SkillBars skills={config.techSkills} hue="192" saturation="100" />
                    </Fade>
                  </Box>

                  <Box animation="fadeIn" justify="center" gridArea="commHeading" background="accent-3">
                    <Heading textAlign="center" level={4}>Communication</Heading>
                  </Box>
                  <Box justify="center" gridArea="commInfo" background="dark-1">
                    <Fade left cascade big fraction={0.3}>
                      <Box pad="small">
                        <Text alignSelf="stretch">
                          Excellent communication skills, demonstrated through managing group projects in university, and in the workforce.
                        </Text>
                        <Text alignSelf="stretch">
                          Able to work in team-oriented environment to tackle complicated technical problems quickly and efficiently.
                        </Text>
                      </Box>
                    </Fade>
                  </Box>

                  <Box animation="fadeIn" justify="center" gridArea="orgnHeading" background="accent-3">
                    <Heading textAlign="center" level={4}>Organizational</Heading>
                  </Box>
                  <Box justify="center" gridArea="orgnInfo" background="light-4">
                    <Fade left cascade big fraction={0.3}>
                      <Box pad="small">
                        <Text alignSelf="stretch">
                          Detailed and task oriented.
                        </Text>
                        <Text alignSelf="stretch">
                          Demonstrated leadership skills through supervision at Saskatoon Co-Op and leading several group projects through university.
                        </Text>
                        <Text alignSelf="stretch">
                          Capable of taking a leadership role in group situations demonstrated with various group projects, as presented on my website.
                        </Text>
                      </Box>
                    </Fade>
                  </Box>

                </Grid>
                <TimeLine />
              </Box>
    )
}
export default BigResume;
