import { Box, Heading, Text } from "grommet";
import React from "react";
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
const TimeLine = () => {
    return(
        <Box fill>
        <Timeline lineColor={'#ddd'}>
            <TimelineItem key="001"
            dateText="Jan. 2018 - Present"
            style={{color: '#81FCED'}}
            dateInnerStyle={{background: '#81FCED', color: '#000'}}>
                <Heading color="light-3" level={3}>Programmer Analyst</Heading>
                <Heading color="light-3" level={4}>University of Saskatchewan</Heading>
                <Text color="light-3">- Creation of software that extends off the Banner ecosystem, custom applications, and business processes to streamline workflow.<br/></Text>
                <Text color="light-2">- Installation and support of the Banner ecosystem.<br/></Text>
                <Text color="light-3">- Provide support to any bugs that may appear within the system, and push patches as need be.<br/></Text>
                <Text color="light-3">- Dealt with new web technology and moving forward with usage of Git.<br/></Text>
                <Text color="light-2">- Integration of DevOps and learned usage of Jenkins and test libraries for Continuous Integration.<br/></Text>
            </TimelineItem>
            <TimelineItem key="002"
            dateText="Feb. 2017 - Jan. 2018"
            style={{color: '#6FFFB0'}}
            dateInnerStyle={{background: '#6FFFB0', color: '#000'}}>
                <Heading color="light-3" level={3}>Software Developer</Heading>
                <Heading color="light-3" level={4}>Suncorp Valuations</Heading>
                <Text color="light-3">- Creation of in-house software to help streamline workflow for employees.<br/></Text>
                <Text color="light-2">- Support and upgrading of legacy software and systems.<br/></Text>
                <Text color="light-3">- Provide support and operate through the entire software design life-cycle.<br/></Text>
                <Text color="light-3">- Dealt heavily with normalizing data and creating efficient systems optimized for many reads and writes in database systems.<br/></Text>
                <Text color="light-2">- Worked on creating a fast, efficient, user-friendly system for the company to speed up processing of large amounts of data.<br/></Text>
            </TimelineItem>
            <TimelineItem key="003"
            dateText="Apr. 2016 - Present"
            style={{color: '#FD6FFF'}}
            dateInnerStyle={{background: '#FD6FFF', color: '#000'}}>
                <Heading color="light-3" level={3}>Contract Web Developer</Heading>
                <Heading color="light-3" level={4}>Music Direct</Heading>
                <Text color="light-3">- Improving website functionality by implementing different modules for the content management system.<br/></Text>
                <Text color="light-2">- Migrate the website towards an e-commerece solution under Wordpress and Woocommerece.<br/></Text>
                <Text color="light-3">- Adding and removing inventory as new stock arrives.<br/></Text>
                <Text color="light-3">- Creating a new, custom domain email server for the client.<br/></Text>
            </TimelineItem>
            <TimelineItem key="004"
            dateText="Nov. 2014 - Mar. 2017"
            style={{color: '#FFCA58'}}
            dateInnerStyle={{background: '#FFCA58', color: '#000'}}>
                <Heading color="light-3" level={3}>System Technician</Heading>
                <Heading color="light-3" level={4}>Greater Saskatoon Catholic Schools</Heading>
                <Text color="light-3">- Assisting teachers and other faculty staff with technical problems in regards to computers and other technology, such as fixing network/computer issues.<br/></Text>
                <Text color="light-2">- On-site visits for techinical issues.<br/></Text>
            </TimelineItem>
        </Timeline>
        </Box>
    )
}
export default TimeLine;