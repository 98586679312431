import IndButton from "../components/IndButton";
import config from "../config/config.json";
import { Box, Grommet, Heading, Image, ResponsiveContext, Text } from "grommet";
import React from "react";
import MainBody from "../components/MainBody";
import Profile from "../img/profile.jpg";

const Home = () => {
  return (
    <Grommet theme={config.theme} full>
      <ResponsiveContext.Consumer>
        {size => (
          <MainBody heading="Home">
            <Box
              flex
              align="center"
              pad="small"
              overflow={{ horizontal: "hidden" }}
            >
              <Image className="profile" src={Profile} />
                <Heading
                  level="1"
                  pad="small"
                  margin="none"
                  a11yTitle="Evan Closson"
                >
                  Evan Closson
                </Heading>
                <Heading
                  level="4"
                  margin="none"
                  justify="center"
                  a11yTitle="Software Developer, Bachelor of Science, Serial Hobbyist"
                >
                  Software Developer | B.Sc | Serial Hobbyist
                </Heading>              
              {size !== "small" && (
                <Box direction="row" justify="center">
                  {config.pages.map((pageInfo, i) => {
                    return pageInfo[1] === "/" ? (
                      ""
                    ) : (
                      <IndButton
                        key={i}
                        icon={pageInfo[0]}
                        link={pageInfo[1]}
                        title={pageInfo[2]}
                      />
                    );
                  })}
                </Box>
              )}
              <hr />
              <Box
                direction="row"
                justify="center"
                overflow={{ horizontal: "hidden" }}
                animation="fadeIn">
              <Text a11yTitle="small info" textAlign="center">
                  Hi there! I'm Evan. I'm a software developer, currently
                  working for the University of Saskatchewan. <br/>
                  If you'd like to know more about me{" "}
                  {size !== "small"
                    ? "please click the buttons to learn!"
                    : "please click the menu button to learn!"}
              </Text>
              </Box>
            </Box>
          </MainBody>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
};
export default Home;
